import { NextInit } from '@next/next-angular-kit';
import { NextAgendaInit } from '@next/next.agenda';

export const environment = {
  production: false,
  defaultLanguage: 'it',

  nextInit: {
    baseUrl: 'https://api.pergolizzi.net-serv.it/',
    clientId: 'b0ff58f48f377ef69c5abfea745654372ec32013.pergolizzi.backoffice.admin',
    clientSecret: 'e8f0c84c-cae0-3750-d890-c79666c6a36f'
  } as NextInit,

  nextAgendaInit: {
    clientId: 'b0ff58f48f377ef69c5abfea745654372ec32013.studiopergolizzi.client',
    clientSecret: 'a5487652-123c-b556-aa98-f6f5f661cbf38gteaf347aqfertvmpod',
    baseUrl: 'https://agenda.pergolizzi.net-serv.it',
    version: 1,
    domain: 'PERGOLIZZI'
  } as NextAgendaInit,

  fullCalendar: {
    licenseKey: '0817257438-fcs-1611743453'
  },

  googleMaps: {
    apiKey: 'AIzaSyCnxe5Geee90B0embJLHTGNezMbSQ6iEto',
    mapUrl: 'https://maps.googleapis.com/maps/api/js?key='
  }
};

