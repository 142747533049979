import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@next/next-angular-kit';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/core/core.module').then(m => m.CoreModule)
  },
  {
    path: 'administrator',
    loadChildren: () => import('./components/administrator/administrator.module').then(m => m.AdministratorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'internal-collaborator',
    loadChildren: () => import('./components/internal-collaborator/internal-collaborator.module').then(m => m.InternalCollaboratorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'external-collaborator',
    loadChildren: () => import('./components/external-collaborator/external-collaborator.module').then(m => m.ExternalCollaboratorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'doctor',
    loadChildren: () => import('./components/doctor/doctor.module').then(m => m.DoctorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'consultant',
    loadChildren: () => import('./components/consultant/consultant.module').then(m => m.ConsultantModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer',
    loadChildren: () => import('./components/end-user/end-user.module').then(m => m.EndUserModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'error/not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
