import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NextError } from '@next/next-angular-kit';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly toastrService: ToastrService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<string>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorResponse: NextError | any = error.error;

        if (!req.url.includes('login') && (error.status === 400 || error.status === 409)) {
          this.toastrService.error(errorResponse.uiMessage || ('title' in errorResponse ? errorResponse.title : ''));
        }

        if (error.status === 404 && !req.url.includes('JobDescription/GetById')) {
          this.router.navigate(['error/not-found'], { replaceUrl: true }).then();
        }

        if (error.status === 500 && !req.url.includes('download') && !req.url.includes('RefreshToken')) {
          this.router.navigate(['error/internal-server-error'], { replaceUrl: true }).then();
        }

        return throwError(() => error);
      })
    );
  }
}
