import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { NextModule } from '@next/next-angular-kit';
import { NextAgendaModule } from '@next/next.agenda';
import { HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioDefaultOptions } from '@angular/material/radio';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NextModule.forRoot(environment.nextInit),
    NextAgendaModule.forRoot(environment.nextAgendaInit),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpBackend) => new MultiTranslateHttpLoader(http, [
          './next-angular-kit/i18n/', // Load library translations first, so you can edit the keys in your localization file
          './assets/i18n/' // Your i18n location
        ]),
        deps: [HttpBackend]
      },
      defaultLanguage: environment.defaultLanguage
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    NgxMaterialTimepickerModule.setLocale(environment.defaultLanguage),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() })
  ],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'it' },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } as MatCheckboxDefaultOptions },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } as MatRadioDefaultOptions },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: false, autoFocus: false } as MatDialogConfig },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ]
})
export class AppModule {
}
